export const version = "1.0.0";

export const accountId = "openconjecture";

export const env = "prod";

export const firebaseConfig = {
  apiKey: "AIzaSyC6joNzEs-7W_iQVXAstAq1KuIPAq6X9PQ",
  authDomain: "axon-platform.firebaseapp.com",
  databaseURL: "https://axon-platform.firebaseio.com",
  projectId: "axon-platform",
  storageBucket: "axon-platform.appspot.com",
  messagingSenderId: "933375468355",
  appId: "1:933375468355:web:c479da09cbea27b7d385f6",
};

export const reduxFirebase = {
  enableLogging: "false",
};

export const atlas = {
  serverUrl: "https://atlas-axon.web.app",
};

export const build = {
  baseUrl: "http://localhost:3100",
};

export const sdk = {
  baseUrl: "https://storage.googleapis.com/axon-cdn/sdk",
};

export const configs = {
  baseUrl: "https://storage.googleapis.com/axon-cdn/accounts",
};

export const customer = {
  activityStreamTopicName: "customer-activity",
};

export const agent = {
  ipStackAPIAccessKey: "4d1a98985ea36c7d938ee42f111441bf",
};

export default { version, accountId, env, firebaseConfig, reduxFirebase, atlas, build, sdk, configs, customer, agent };
