import React from "react";

// Import Dashboard Page
// import DashboardPage from "./DashboardPage";

const DashboardLayout = ({ accountId, environment }) => (
  <div>
    <span>Account Id: {accountId}</span>
    <br/>
    <span>Environment: {environment}</span>
  </div>
);

export default DashboardLayout;
