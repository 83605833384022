import React from 'react'
import { useSelector } from 'react-redux'
import { isLoaded, isEmpty } from 'react-redux-firebase'

// Material UI
import { Link } from 'react-router-dom'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import Button from '@material-ui/core/Button'
import AddBoxIcon from '@material-ui/icons/AddBox'
import Typography from '@material-ui/core/Typography'
import InputBase from '@material-ui/core/InputBase'
import SearchIcon from '@material-ui/icons/Search'
import { makeStyles } from '@material-ui/core/styles'

// Config
import { accountId } from './../../firebaseConfig'

// App-specific
import { LOGIN_PATH } from 'constants/paths'
import AccountMenu from './AccountMenu'

// Styles
import styles from './Navbar.styles'
const useStyles = makeStyles(styles)

// Helper - Format account title
function titleCase(str) {
   var splitStr = str.toLowerCase().split('-');
   for (var i = 0; i < splitStr.length; i++) {
       // You do not need to check if i is larger than splitStr length, as your for does that for you
       // Assign it back to the array
       splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
   }
   // Directly return the joined string
   return splitStr.join(' ');
}

function Navbar() {
  const classes = useStyles()

  // Get auth from redux state
  const auth = useSelector(state => state.firebase.auth)
  const authExists = isLoaded(auth) && !isEmpty(auth)

  return (
    <div className={classes.root}>
      <AppBar position="static" className={classes.appBar}>
        <Toolbar>
          <Typography id="nav-bar-title" className={classes.title} variant="h6" noWrap>
            Atlas | {titleCase(accountId)}
          </Typography>
          <div id="search-bar" className={classes.search}>
            <div className={classes.searchIcon}>
              <SearchIcon />
            </div>
            <InputBase
              placeholder="Search…"
              classes={{
                root: classes.inputRoot,
                input: classes.inputInput,
              }}
              inputProps={{ 'aria-label': 'search' }}
            />
          </div>

          {authExists ? (
            <Button
              variant="contained"
              className={classes.button}
              startIcon={<AddBoxIcon />}
            >
              Add Client
            </Button>
          ) : (
            <></>
          )}

          {authExists ? (
             <AccountMenu />
           ) : (
             <Button
               className={classes.signIn}
               component={Link}
               to={LOGIN_PATH}
               data-test="sign-in">
               Sign In
             </Button>
           )}
        </Toolbar>
      </AppBar>
    </div>
  );
}

export default Navbar
