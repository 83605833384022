import React from 'react';
import { connect } from 'react-redux';

// Import Dashboard Page
import DashboardLayout from "../../components/DashboardLayout";

// Config
import { version, accountId, env } from './../../../../firebaseConfig'

function Home({ session }) {
  return (
    <div id="container" className="container">
      <DashboardLayout accountId={accountId} environment={env} />
    </div>
  )
}

const mapStateToProps = state => ({
  session: state.session
})

export default connect(mapStateToProps)(Home)
